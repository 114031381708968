import './Contatti.css'
import contattiImg from '../img/contatti2.png'
import { useEffect } from 'react'

export default function Contatti() {
  useEffect(() => {
    document.querySelector('.current-page').classList.remove('current-page')
    document.querySelector('#nav-contatti').classList.add('current-page')
  }, [])
  return (
    <main className='contatti-main radius-shadow'>
      <h1>Contatti</h1>
      <section className='contatti-section'>
        <aside className='contatti-section-aside'>
          <a href='https://www.linkedin.com/in/yari-piras-3300b31a4/' target='_blank' rel='noreferrer'>Profilo LinkedIn</a>
          <a href='https://github.com/Arboricum/' target='_blank' rel='noreferrer'>Profilo GitHub</a>
          <a href='https://www.frontendmentor.io/profile/Arboricum' target='_blank' rel='noreferrer'>Profilo Frontend Mentor</a>
          <a href='https://yaripiras.wixsite.com/javascript-facile/blog' target='_blank' rel='noreferrer'>Blog</a>
          <a href='mailto:arboricum@yaripiras.it'>Email</a>
        </aside>
        <img src={contattiImg} alt='un telefono e un bloc notes'/>
      </section>
    </main>
  )
}
