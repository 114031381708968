import './Portfolio.css'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import softImg from '../img/soft.png'
import sitiImg from '../img/siti.png'

export default function Portfolio() {
  useEffect(() => {
    document.querySelector('.current-page').classList.remove('current-page')
    document.querySelector('#nav-portfolio').classList.add('current-page')
  }, [])
  return (
   <main className="portfolio-main radius-shadow">
    <h1>Portfolio</h1>
    <section className='portfolio-section'>
      <Link className='siti-container' to='/portfolio/siti'>
        <h2>Siti</h2>
        <img src={sitiImg} alt='placeholder per siti'/>
      </Link>
      <Link className='software-container' to='/portfolio/soft'>
        <h2>Software</h2>
        <img src={softImg} alt='placeholder per software'/>
      </Link>
    </section>
   </main>
  )
}
