import './Curriculum.css'
import { useEffect } from 'react'
import { useFetch } from '../hooks/useFetch'
/* import certifications from '../lists/certifications' */

export default function Curriculum() {
  /* const { certificationsList } = certifications() */
  const { data, isPending, error } = useFetch('/jsonLists/certifications.json')
  useEffect(() => {
    document.querySelector('.current-page').classList.remove('current-page')
    document.querySelector('#nav-curriculum').classList.add('current-page')
  }, [])
  console.log(data, 'data')
    const certificationsList = data ? data.certifications : null
    console.log(certificationsList, "certificationsList")
  return (
    <main className="curriculum-main radius-shadow">
    <h1>Curriculum vitae</h1>
    <article className="curriculum">
      <h2>Competenze Tecniche:</h2>
      <ul>
        <li>
          <h3>Linguaggi di Programmazione:</h3>
          <ul>
            <li>Ottimo: JavaScript, TypeScript, HTML, CSS, XML, JSON</li>
            <li>Buono: Node.js, Git, Sass</li>
            <li>Base: PHP</li>
          </ul>
        </li>
        <li>
          <h3>Framework e Librerie:</h3>
          <ul>
            <li>Ottimo: React, React.Native, Next.JS, AJAX, Firebase</li>
            <li>Buono: Bootstrap, Leaflet, Canvas, SVG, Web-Audio API</li>
            <li>Base: Angular, JQuery </li>
          </ul>
        </li>
        <li>
          <h3>Paradigmi di Programmazione:</h3>
          <ul>
            <li>Object-Oriented Programming (OOP)</li>
            <li>Programmazione Funzionale</li>
          </ul>
        </li>
        <li>
          <h3>Altro:</h3>
          <ul>
            <li>Conoscenza della logica algoritmica e degli algoritmi base</li>
            <li>Conoscenza di Bash e DOS</li>
            <li>Conoscenza dei principi di Object-Oriented Design (OOD) e Design Patterns</li>
            <li>Conoscenza di UI/UX (User Interface/User Experience) design</li>
            <li>Familiarità con l'accessibilità web (ARIA - Accessible Rich Internet Applications)</li>
            <li>Conoscenza del protocollo MIDI</li>
          </ul>
        </li>
        <li>
          <h3>Software:</h3>
          <ul>
            <li>Strumenti di Sviluppo: Visual Studio Code, Notepad++, XAMPP</li>
            <li>Grafica e Design: Adobe Illustrator, MIT App Inventor, Scratch</li>
            <li>CMS: WordPress, Wix, One</li>
            <li>Suite Office, Browser</li>
          </ul>
        </li>
      </ul>

      <h2>Istruzione Informatica: </h2>
      <ul>
        <li>Diploma di Liceo Scientifico ad Indirizzo Informatico presso il Liceo "A. Einstein" di Milano</li>
        <li>Certificazioni:
          <ul className='certificazioni'>
            {isPending && <p>Loading...</p>}
            {error && <p>{error}</p>}
            {certificationsList && certificationsList.map((certification, index) => {
              return <li key={'cert'+index}><a href={certification.link} target='_blank' rel='noreferrer'>{certification.name}</a></li>
            })}
          </ul>
        </li>
        <li>Preparazione su numerosi testi specialistici, sia in Italiano che Inglese</li>
      </ul>
  
      <h2>Esperienza Professionale:</h2>
      <ul>
        <li>Sviluppo di diversi software per siti web utilizzando varie tecniche e linguaggi</li>
        <li>Collaborazione per l'aggiornamento quotidiano della homepage di Libero.it</li>
        <li>Webmaster freelance negli ultimi 4 anni</li>
        <li>Ideazione e sviluppo di nuove e originali applicazioni web, con una particolare inclinazione verso la musica e la multimedialità</li>
        <li>Decennale lavoro con numerose tipologie di software per la creazione di opere artistiche e non</li>
      </ul>
   
      <h2>Lingue Straniere:</h2>
      <ul>
        <li>Inglese: Buona conoscenza parlata, ottima scritta</li>
      </ul>
   
      <h2>Soft Skills:</h2>
      <ul>
        <li>Facilità di apprendimento</li>
        <li>Eccellenti doti comunicative</li>
        <li>Capacità di problem-solving</li>
        <li>Orientato al lavoro in squadra</li>
        <li>Adattabilità e flessibilità</li>
        <li>Capacità di gestire lo stress e lavorare sotto pressione</li>
        <li>Creatività e pensiero innovativo</li>
        <li>Organizzazione e gestione del tempo</li>
      </ul>
          
      <h2>Istruzione (Altro):</h2>
      <ul>
        <li>Laurea Specialistica in Lettere con il massimo dei voti presso l'Università degli Studi di Milano</li>
      </ul>
          
      <h2>Interessi e Hobby:</h2>
      <ul>
        <li>Studi di composizione sperimentale al Conservatorio di Milano</li>
        <li>Polistrumentista</li>
        <li>Canali su YouTube, Spotify e SoundCloud</li>
        <li>Pubblicazione di libri di poesie e racconti</li>
        <li>Appassionato giocatore e studioso di scacchi</li>
      </ul>

      <h2>Ulteriori Informazioni:</h2>
      <ul>
        <li>In parallelo all'attività professionale, sto sviluppando una significativa esperienza nell'utilizzo di ChatGPT, un modello di linguaggio AI avanzato. Questa esperienza sta ampliando la mia comprensione delle potenzialità delle tecnologie emergenti e la loro applicazione pratica</li>
      </ul>
    </article>
  </main>
  )
}
