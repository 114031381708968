import { Link, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import './Portfolio.css'
import { useFetch } from '../hooks/useFetch'

export default function PortfolioSub() {
    const { menu } = useParams()
    const { data, isPending, error } = useFetch('/jsonLists/list.json')

    useEffect(() => {
        document.querySelector('.current-page').classList.remove('current-page')
        document.querySelector('#nav-portfolio').classList.add('current-page')
    }, [])

    console.log(data, 'data')
    const contents = data ? data[menu] : null
    console.log(contents, "contents")

    return (
        <main className='portfolioSub-main radius-shadow'>
            {isPending && <p>Loading...</p>}
            {error && <p>{error}</p>}
            {contents && (
                <>
                    <h1>{contents.name}</h1>
                    <div className='back-link-container'>
                        <Link to='/portfolio' className='back-link'>&lt;&lt;&nbsp;Portfolio</Link>
                        <span>&nbsp;-&nbsp;{contents.name}</span>
                    </div>
                </>
            )}
            {contents && contents.catalog.map((content, index) => (
                <div key={'content'+index} className='content-container'>
                    {content.title && <h2>{content.title}</h2>}
                    <a href={content.address} target='_blank' rel='noreferrer'>{content.address}</a>
                    <p>{content.desc}</p>
                    {content.mobile === 'true' ? <i>Ottimizzato per cellulare</i> : <i>Non ottimizzato per cellulare</i>}
                    <i>Anno: {content.year}</i>
                </div>
            ))}
        </main>
    )
}
