import { Link } from "react-router-dom"
import './Navbar.css'

export default function Navbar() {
  return (
    <nav className="navbar">
      <ul>
        <li><Link to='/' id="nav-home" className="current-page">HOME</Link></li>
        <li><Link to='/curriculum' id="nav-curriculum">CURRICULUM</Link></li>
        <li><Link to='/portfolio' id="nav-portfolio">PORTFOLIO</Link></li>
        <li><Link to='/contatti' id="nav-contatti">CONTATTI</Link></li>
      </ul>
    </nav>
  )
}
