import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Curriculum from './pages/Curriculum';
import Portfolio from './pages/Portfolio';
import PortfolioSub from './pages/PortfolioSub';
import Contatti from './pages/Contatti';

function App() {
  return (
    <div className="App">
    <BrowserRouter>      
        <Header />
        <Routes>
          <Route path='/' element={<Home />}/>
          <Route path='/curriculum' element={<Curriculum />}/>
          <Route path='/portfolio' element={<Portfolio />}/>
          <Route path='/portfolio/:menu' element={<PortfolioSub />}/>
          <Route path='/contatti' element={<Contatti />}/>
        </Routes>
        <Footer />      
    </BrowserRouter>
    </div>
  );
}

export default App;
